import styled from "styled-components";
import { HexColor } from "../../../types/styles";

export const StyledAlert = styled.div<{ color: HexColor; small: boolean }>`
  position: relative;
  display: flex;
  padding: ${({ small }) => (small ? "6px" : "16px")};
  gap: ${({ small }) => (small ? "8px" : "10px")};
  border-radius: 5px;
  background-color: ${({ color }) => color};
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

export const StyledText = styled.span<{
  color: HexColor;
  bold: boolean;
  closable: boolean;
  small: boolean;
  gutterBottom?: boolean;
}>`
  line-height: 16px;
  font-size: 13px;
  padding-top: ${({ small }) => (small ? "1px" : "2.5px")};
  color: ${({ color }) => color};

  ${({ gutterBottom }) =>
    gutterBottom &&
    `
    margin-bottom: 10px;
    display: block;
  `}

  ${({ bold }) =>
    bold &&
    `
    font-weight: 700;
  `}

  ${({ closable }) =>
    closable &&
    `
    padding-right: 30px;
  `}
`;

export const StyledChildrenWrapper = styled.span<{
  color: HexColor;
}>`
  font-size: 13px;
  color: ${({ color }) => color};
`;

export const StyledCloseButton = styled.button`
  cursor: pointer;
  margin-left: auto;
  padding: 0;
  height: 16px;
  border: none;
  background: transparent;
`;
