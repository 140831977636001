import React, { FC, ReactNode, useState } from "react";
import {
  StyledAlert,
  StyledChildrenWrapper,
  StyledCloseButton,
  StyledText,
} from "./index.styled";
import {
  CheckIconSvg,
  CloseIconSvg,
  ErrorIconSvg,
  InfoIconSvg,
  LockIconSvg,
  WarningIconSvg,
} from "../../../components/icons";
import { HexColor } from "../../../types/styles";
import { colors } from "../../../styles/variables";

export type AlertTypes = "error" | "info" | "lock" | "success" | "warning";

type AlertPropertyMap = {
  [key in AlertTypes]: {
    textColor: HexColor;
    backgroundColor: HexColor;
    icon: JSX.Element;
  };
};

export const alertPropertyMap: AlertPropertyMap = {
  error: {
    textColor: colors.red,
    backgroundColor: "#FAE5E6",
    icon: <ErrorIconSvg color={colors.red} />,
  },
  info: {
    textColor: colors.blue,
    backgroundColor: "#E6F5FE",
    icon: <InfoIconSvg color={colors.blue} />,
  },
  lock: {
    textColor: colors.blue,
    backgroundColor: "#E6F5FE",
    icon: <LockIconSvg color={colors.blue} />,
  },
  success: {
    textColor: colors.green,
    backgroundColor: "#E6F3EC",
    icon: <CheckIconSvg color={colors.green} />,
  },
  warning: {
    textColor: colors.brown,
    backgroundColor: "#FEF3E6",
    icon: <WarningIconSvg color={colors.brown} />,
  },
};

interface AlertProps {
  bold?: boolean;
  children: ReactNode | string;
  className?: string;
  closable?: boolean;
  onClose?: () => void;
  small?: boolean;
  title?: string;
  type?: AlertTypes;
}

export const Alert: FC<AlertProps> = ({
  bold = false,
  children,
  className,
  closable = false,
  onClose,
  small = false,
  title,
  type = "info",
}) => {
  const [close, setClose] = useState(false);
  const { textColor, backgroundColor, icon } = alertPropertyMap[type];

  const handleClose = () => {
    if (onClose) onClose();
    setClose(true);
  };

  if (close) return null;

  return (
    <StyledAlert
      className={className || ""}
      color={backgroundColor}
      small={small}
      data-testid="alert"
      role="alert"
    >
      {icon}
      <div>
        {title && (
          <div>
            <StyledText
              color={textColor}
              bold
              closable={closable}
              small={small}
              gutterBottom
            >
              {title}
            </StyledText>
          </div>
        )}
        {typeof children === "string" ? (
          <StyledText
            color={textColor}
            bold={bold}
            closable={closable}
            small={small}
          >
            {children}
          </StyledText>
        ) : (
          <StyledChildrenWrapper color={textColor}>
            {children}
          </StyledChildrenWrapper>
        )}
      </div>
      {closable && (
        <StyledCloseButton onClick={handleClose}>
          <CloseIconSvg color={textColor} />
        </StyledCloseButton>
      )}
    </StyledAlert>
  );
};
